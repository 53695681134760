import * as React from "react";
import NextLink from "next/link";
import Image from 'next/image'
import useMediaQuery from '@mui/material/useMediaQuery';
import sfpro from '@/theme/localFont';
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import logoImg from '../../../public/assets/clapps/images/logo.svg';
import onlineImg from '../../../public/assets/clapps/images/header-icon-online.svg';
import burgerImg from '../../../public/assets/clapps/images/header-icon-burger.svg';
import IconFlaticon from "@/components/IconFlaticon";
import telegramIcon from "../../../public/assets/clapps/images/icon-telegram.svg";
import whatsappIcon from "../../../public/assets/clapps/images/icon-whatsapp.svg";

export default function Header(props){
    const [isOpen, setIsOpen] = React.useState(false);
    const mediaMaxSm = useMediaQuery('(max-width:767px)', { noSsr: true })
    const [screenMobile, setScreenMobile] = React.useState(props.device === 'mobile');

    React.useEffect(() => {
        setScreenMobile(mediaMaxSm)
    }, [mediaMaxSm]);

    const handleMenuClose = () => {
        setIsOpen(false);
    }

    return(
        <header className={'header_block'} itemScope itemType="http://schema.org/WPHeader">
            <div className={'header_div'}>
                <nav>
                    <ul>
                        <li>
                            <NextLink href={'/'}>
                                <div className={'header_logo'}>
                                    <Image 
                                        src={logoImg}
                                        alt="Логотип CLapps"
                                        quality={100}
                                        sizes="100vw"
                                        style={{
                                            width: 'auto',
                                            height: '100%',
                                        }}
                                    />
                                </div>
                            </NextLink>
                        </li>
                    </ul>
                </nav>
                <div className={'header_block_nav'}>
                    <nav>
                        <ul itemScope itemType="http://schema.org/SiteNavigationElement" className={'header_ul'}>
                            <li>
                                <NextLink itemProp="url" href={'/dev'} className={'header_ul_li'}>    {/*href={'/#products'}*/}
                                    Разработки
                                </NextLink>
                            </li>
                            <li>
                                <NextLink itemProp="url" href={'/ready'} className={'header_ul_li'}>   {/*href={'/#solutions'}*/}
                                    Готовые решения
                                </NextLink>
                            </li>
                            <li>
                                <NextLink itemProp="url" href={'/#portfolio'} className={'header_ul_li'}>   {/*href={'/lp-portfolio'}*/}
                                    Портфолио
                                </NextLink>
                            </li>
                            <li>
                                <NextLink itemProp="url" href={'/contacts'} className={'header_ul_li'}>
                                    Контакты
                                </NextLink>
                            </li>
                        </ul>
                    </nav>
                    <div className={'header_ul_small'}>
                        <Image
                            src={onlineImg}
                            alt="ICON"
                            quality={100}
                            sizes="100vw"
                            style={{
                                width: 'auto',
                                height: '100%',
                            }}
                        />
                        <NextLink rel="nofollow" href={'tel:89246053995'} className={'header_ul_li'}>+7(924)605-39-95</NextLink>
                    </div>
                </div>
                <div className={'flex md:hidden w-full justify-end items-center'} onClick={() => setIsOpen(true)}>
                    <Image
                        src={burgerImg}
                        alt="BURGER"
                        quality={100}
                        sizes="100vw"
                        style={{
                            width: '24px',
                            height: '100%',
                        }}
                    />
                </div>
            </div>
            {screenMobile && (
                <React.Fragment key={'left'}>
                    <SwipeableDrawer
                        anchor={'left'}
                        open={isOpen}
                        onClose={() => {
                            setIsOpen(false)
                        }}
                        onOpen={() => {
                            setIsOpen(true)
                        }}
                    >
                        <div className={`flex flex-col w-72 h-full bg-white ${sfpro.className}`}>
                            <nav>
                                <ul itemScope itemType="http://schema.org/SiteNavigationElement" className={'w-full'}>
                                    <li className={'flex flex-row w-full pl-12 justify-between'}>
                                        <NextLink itemProp="url" href={'/'} className={'mt-8 justify-between'} onClick={handleMenuClose}>
                                            <Image
                                                src={logoImg}
                                                alt="Логотип CLapps"
                                                quality={100}
                                                sizes="100vw"
                                                style={{
                                                    width: 'auto',
                                                    height: '40px'
                                                }}
                                            />
                                        </NextLink>
                                        <div className={'py-3 px-3'} onClick={handleMenuClose}>
                                            <IconFlaticon name={'br-cross-small'} className={'text-3xl text-red-1'} />
                                        </div>
                                    </li>
                                    <li className={'w-full mt-9 pl-12'}>
                                        <NextLink itemProp="url" href={'/dev'} className={'flex text-sm text-black-2 font-bold'} onClick={handleMenuClose}>   {/*href={'/#products'}*/}
                                            Разработки
                                        </NextLink>
                                    </li>
                                    <li className={'mt-7 pl-12'}>
                                        <NextLink itemProp="url" href={'/ready'} className={'flex text-sm text-black-2 font-bold'} onClick={handleMenuClose}>  {/*href={'/#solutions'}*/}
                                            Готовые решения
                                        </NextLink>
                                    </li>
                                    <li className={'mt-7 pl-12'}>
                                        <NextLink itemProp="url" href={'/#portfolio'} className={'flex text-sm text-black-2 font-bold'} onClick={handleMenuClose}>  {/*href={'/lp-portfolio'}*/}
                                            Портфолио
                                        </NextLink>
                                    </li>
                                    <li className={'mt-7 pl-12'}>
                                        <NextLink itemProp="url" href={'/contacts'} className={'flex text-sm text-black-2 font-bold'} onClick={handleMenuClose}>
                                            Контакты
                                        </NextLink>
                                    </li>
                                </ul>
                            </nav>
                            <div>
                                <div className={'mt-7 pl-12 py-4 border-solid border-0 border-y border-white-1'}>
                                    <NextLink href={'tel:89246053995'} className={'flex text-sm text-black-1 font-normal'} onClick={handleMenuClose}>
                                        +7(924)605-39-95
                                    </NextLink>
                                </div>
                                <div className={'pl-12 py-4 border-solid border-0 border-y border-white-1'}>
                                    <NextLink rel="nofollow" href={'https://t.me/+79246053995'} className={'flex flex-row items-center text-sm text-black-1 font-normal'} onClick={handleMenuClose}>
                                        <Image
                                            src={telegramIcon}
                                            alt="IMAGE"
                                            quality={100}
                                            // sizes="100vw"
                                            style={{
                                                width: '26px',
                                                height: 'auto',
                                                marginRight: '0.5rem'
                                            }}
                                        />
                                        Telegram
                                    </NextLink>
                                </div>
                                <div className={'pl-12 py-4 border-solid border-0 border-y border-white-1'}>
                                    <NextLink rel="nofollow" href={'https://wa.clck.bar/79246053995'} className={'flex flex-row items-center text-sm text-black-1 font-normal'} onClick={handleMenuClose}>
                                        <Image
                                            src={whatsappIcon}
                                            alt="IMAGE"
                                            quality={100}
                                            // sizes="100vw"
                                            style={{
                                                width: '26px',
                                                height: 'auto',
                                                marginRight: '0.5rem'
                                            }}
                                        />
                                        WhatsApp
                                    </NextLink>
                                </div>
                            </div>
                        </div>
                    </SwipeableDrawer>
                </React.Fragment>
            )}
        </header>
    )
}