import * as React from 'react';
import clsx from 'clsx';


function IconFlaticon(props){

  const nameIcon = `fi fi-${props.name}`

  return(
    <i className={clsx('row-center', nameIcon, props.className)} />
  )
}

export default IconFlaticon